<template>
  <div ref="dishes">
    <!-- <Headers :currentindex="3"/> -->
    <div class="banner">
      <p>名厨参谱</p>
    </div>
    <div class="content">
      <ul>
        <li>
          <div class="dishbox">
            <img class="goodsimg" src="../assets/dishes1.png" alt="">
            <div class="right">
              <h3>海参芹菜粥</h3>
              <div class="star"><img v-for="(item,index) of 5" :key="index" src="../assets/star.png" alt=""></div>
              <!-- <div class="sharebox" style="display: flex;justify-content: space-between;align-items: center;">
                <a href="http://service.weibo.com/share/share.php?title=测试&url=https://www.meishi.com&pic=https://img.t.sinajs.cn/t4/appstyle/widget/images/library/base/wb_logo.png?id=1395901125982" target="_blank">
                  <img class="share" style="width: 20px;height: 20px;" src="../assets/share.png" alt="">
                </a>
              </div> -->
              <div class="material">
                <span>原料</span>
                <p>海参、芹菜、大米、盐、姜</p>
              </div>
              <div class="practice">
                <span class="txt">做法</span>
                <div class="practicebox">
                  <p><span>1</span>将大米洗净并浸泡30分钟，锅中倒入清水，大火煮开后，倒入大米熬煮，后改成中小火，煮30分钟。</p>
                  <p><span>2</span>趁着这个时间，将芹菜切成碎末，海参切成薄片，姜去皮切成细丝。</p>
                  <p><span>3</span>待米粥变得粘稠后，放入姜丝，海参片和芹菜，调入盐，搅拌均匀后，改成大火继续煮3分钟，在煮的时候，要不停的用勺子搅拌，以免糊底。</p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="dishbox">
            <img class="goodsimg" src="../assets/dishes2.png" alt="">
            <div class="right">
              <h3>海参蒸蛋</h3>
              <div class="star"><img v-for="(item,index) of 5" :key="index" src="../assets/star.png" alt=""></div>
              <div class="material">
                <span>原料</span>
                <p>水发海参、鸡蛋、适量的盐、胡椒粉等</p>
              </div>
              <div class="practice">
                <span class="txt">做法</span>
                <div class="practicebox">
                  <p><span>1</span>瓷碗中倒入一点水，将鸡蛋打入，海参丁放入，盐少许，胡椒粉少许，搅拌均与，如果喜欢香菜、生姜，可以放入一点，可以掩盖鸡蛋和海参的鲜腥味。</p>
                  <p><span>2</span>将瓷碗放入锅中蒸10-15分钟，出炉即可。也可以放在微波炉里蒸10-15分钟。</p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="dishbox">
            <img class="goodsimg" src="../assets/dishes3.png" alt="">
            <div class="right">
              <h3>葱烧海参</h3>
              <div class="star"><img v-for="(item,index) of 5" :key="index" src="../assets/star.png" alt=""></div>
              <div class="material">
                <span>原料</span>
                <p>海参、香椿芽、西兰花、鱼籽、水淀粉、高汤、黄酒、老抽、酱油、盐、胡椒粉、葱姜、糖</p>
              </div>
              <div class="practice">
                <span class="txt">做法</span>
                <div class="practicebox">
                  <p><span>1</span>将姜切片、西兰花削成小朵、葱切段；</p>
                  <p><span>2</span>开水锅烧热倒入海参焯水捞出；</p>
                  <p><span>3</span>另做一锅鸡汤放入一个大料瓣，将海参略煮关火沁入味出锅，不要煮的时间过长；</p>
                  <p><span>4</span>锅烧热放入油，将葱段炸黄制成葱油，将葱段捞出，葱油沥出备用；</p>
                  <p><span>5</span>用刚才用过的鸡汤焯熟西兰花；</p>
                  <p><span>6</span>锅内做油煸香姜片，海参下锅，加入葱段；</p>
                  <p><span>7</span>加入清汤100克和酱油，食盐，黄酒，少许胡椒粉；</p>
                  <p><span>8</span>用微火炖2---5分钟，用水淀粉勾芡，可以加一点老抽调色淋上刚才的葱油出锅即可。撒上新鲜鱼籽摆盘、香椿芽装饰。</p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="dishbox">
            <img class="goodsimg" src="../assets/dishes4.png" alt="">
            <div class="right">
              <h3>海参小豆腐</h3>
              <div class="star"><img v-for="(item,index) of 5" :key="index" src="../assets/star.png" alt=""></div>
              <div class="material">
                <span>原料</span>
                <p>海参、木耳、豆腐、芦笋、胡萝卜、生菜、水淀粉、盐、鸡精、葱、姜</p>
              </div>
              <div class="practice">
                <span class="txt">做法</span>
                <div class="practicebox">
                  <p><span>1</span>将大米洗净并浸泡30分钟，锅中倒入清水，大火煮开后，倒入大米熬煮，后改成中小火，煮30分钟。</p>
                  <p><span>2</span>趁着这个时间，将芹菜切成碎末，海参切成薄片，姜去皮切成细丝。</p>
                  <p><span>3</span>待米粥变得粘稠后，放入姜丝，海参片和芹菜，调入盐，搅拌均匀后，改成大火继续煮3分钟，在煮的时候，要不停的用勺子搅拌，以免糊底。</p>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="dishbox">
            <img class="goodsimg" src="../assets/dishes5.png" alt="">
            <div class="right">
              <h3>凉拌海参</h3>
              <div class="star"><img v-for="(item,index) of 5" :key="index" src="../assets/star.png" alt=""></div>
              <div class="material">
                <span>原料</span>
                <p>水发海参、香菜、香油、酱油、料酒、大蒜、盐、味精、白砂糖</p>
              </div>
              <div class="practice">
                <span class="txt">做法</span>
                <div class="practicebox">
                  <p><span>1</span> 大蒜去皮捣成蒜茸放入大碗内，加入料酒、酱油、精盐、白糖、味精和香油调成味汁。</p>
                  <p><span>2</span>水发海参，切成片状，与调好的味汁搅匀。</p>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="goods">
      <div class="goodsintr goodsintr1">
        <img src="../assets/goods1.png" alt="">
        <div class="txt">
          <p class="names">鲜冻红刺参（整参含桂花蚌）</p>
          <p class="ennames">Frozen whole red sea cucumber</p>
          <div class="te">
            <p>产地：加拿大 BC 省</p>
            <p>净重：454g</p>
            <p>工艺：原始淡干工艺</p>
          </div>
          <p class="intr">加拿大野生红刺参主产于加拿大西海岸纯净海域，由于海水温度极低，红参生长周期极长（最长可达10年以上），肉质肥厚，胶质充实，营养物质丰富。体壁上有五条特别粗壮的筋，又名“桂花蚌”，是红参的精华。加拿大红刺参是海参之王。 鲜冻红刺参是由潜水员捕捞上岸后，进行原生态加工、定型、固化蛋白质。食用时仍需煮40-60分钟不等，煮熟后凉拌或爆炒等放入各样菜品。</p>
        </div>
      </div>
    </div>
    <Footers/>
  </div>
</template>
<script>
import Headers from './components/header'
import Footers from './components/footer'
export default {
  components: {
    Headers,
    Footers
  },
  data () {
   return {}
  },
  mounted() {
    
  },
  methods:{}
}
</script>
<style lang="scss" scoped>
@font-face {
  font-family: 'usbth';
  src: url('./../assets/font/usbth.ttf');
}
.banner {
  width: 100%;
  height: 566px;
  background: #000 url('./../assets/bg3.jpg') no-repeat center center;
  background-size: cover;
  color: #fff;
  overflow: hidden;
  p {
    text-align: center;
    font-size: 100px;
    font-weight: 200;
    margin-top: 210px;
  }
}
.content {
  background: #F3F1EB;
  overflow: hidden;
  ul {
    margin-top: 79px;
    margin-bottom: 167px;
    li {
      width: 1132px;
      margin:0 auto;
      background: #fff;
      margin-top: 58px;
      .dishbox {
        padding: 30px 42px 25px 24px;
        display: flex;
        justify-content: space-between;
        .right {
          width: 563px;
          h3 {
            font-size: 24px;
          }
          .star {
            margin:20px 0;
          }
          .material {
            display: flex;
            margin-top: 29px;
            font-size: 14px;
            span {
              display: inline-block;
              width: 60px;
              font-weight:700;
            }
          }
          .practice {
            display: flex;
            margin-top: 21px;
            .txt {
              display: block;
              width: 60px !important;
              font-weight:700;
              font-size: 14px;
            }
            .practicebox {
              width: 487px;
              p {
                font-size: 12px;
                line-height: 20px;
              }
              span {
                color: #D37C28;
                font-size: 14px;
                margin-right: 10px;
                font-family: 'usbth';
                display: inline-block;
                width: 12px;
              }
            }
          }
        }
      }
    }
  }
}
.goods {
  margin-bottom: -70px;
  .goodsintr {
    background: #E6E3DC;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #603E2D;
  }
  .goodsintr1 {
    .txt {
      width: 416px;
      margin-left: 84px;
      .names {
        font-size: 18px;
        font-weight: 600;
      }
      .ennames {
        font-size: 14px;
      }
      .te {
        margin-top: 30px;
        margin-bottom: 20px;
        p {
          font-size: 16px;
        }
      }
      .intr {
        font-size: 16px;
      }
    }
  }
}
@media screen and(max-width: 1400px){
  .content ul li {
    width: 1065px;
  }
  .content ul li .dishbox {
    .goodsimg {
      width: 400px;
      height: auto;
    }
  }
  .goods {
    .goodsintr1 {
      img {
        width: 450px;
      }
      .txt {
        width: 360px;
        margin-left: 40px;
      }
    }
  }
}
@media screen and(max-width: 1180px) {
  .content ul li {
    width: 1000px;
  }
}
@media screen and(max-width: 768px){
  .banner {
    width: 100%;
    height: 580px;
    background: #000 url('./../assets/bg33.jpg') no-repeat center center;
    background-size: cover;
    color: #fff;
    overflow: hidden;
    p {
      width: 128px;
      margin:147px auto 0;
      font-size: 64px;
      font-weight: 400;
    }
  }
  .content {
    background:#F9F8F4;
    overflow: hidden;
    padding:50px 24px 0;
    ul {
      margin-top: 0;
      margin-bottom: 100px;
      li {
        width: 100%;
        margin:0;
        margin-bottom: 50px;
        .dishbox {
          padding: 15px 15px 40px;
          display: flex;
          flex-wrap: wrap;
          .goodsimg {
            width: 100%;
          }
          .right {
            width: 100%;
            h3 {
              font-size: 18px;
              margin-top: 15px;
            }
            .star {
              margin:16px 0;
            }
            .material {
              display: flex;
              flex-wrap: wrap;
              margin-top: 24px;
              font-size: 12px;
              span {
                display: inline-block;
                width: 100%;
                font-size: 14px;
                font-weight:700;
                margin-bottom: 8px;
              }
            }
            .practice {
              display: flex;
              flex-wrap: wrap;
              margin-top: 16px;
              .txt {
                display: block;
                width: 60px !important;
                font-weight:700;
                font-size: 14px;
                margin-bottom: 8px;
              }
              .practicebox {
                width: 487px;
                p {
                  font-size: 12px;
                  line-height: 20px;
                  margin-bottom: 10px;
                }
                span {
                  color: #D37C28;
                  font-size: 16px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  .goods {
    margin-bottom: 0;
    .goodsintr {
      flex-wrap: wrap;
      background:#F9F8F4;
      padding-bottom: 100px;
    }
    .goodsintr1 {
      img {
        width: 100%;
      }
      .txt {
        margin:0;
        padding:0 24px;
        .names {
          font-size: 16px;
        }
        .ennames {
          font-size: 14px;
          margin-top: 8px;
        }
        .te {
          margin-top: 20px;
          margin-bottom: 20px;
          p {
            font-size: 12px;
          }
        }
        .intr {
          font-size: 12px;
        }
      }
    }
  }
}
</style>